<template>
  <div class="article">
    <PageTitle v-bind:title="article ? lemmaDisplay : ''" :pretitle="'Article details'" />
    <ArticleDetails v-bind:article="article" v-if="article" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  import PageTitle from '@/components/PageTitle.vue'
  import ArticleDetails from '@/components/ArticleDetails.vue'

  export default {
    name: 'Article',
    components: {
      ArticleDetails,
      PageTitle
    },
    computed: {
      ...mapState({
        article: state => state.article.article
      }),
      lemmaDisplay(){
        let retval = ''
        let mainLemma = this.article.mainLemma
        if (mainLemma){
          let prefix = ''
          if (mainLemma.scriptas.length){
            prefix = `<small>${mainLemma.scriptas.map(scripta => scripta.code).join(', ')} </small>`
          }
          let sufix = ''
          if (mainLemma.partofspeeches.length){
            sufix = `<small> ${mainLemma.partofspeeches.map(partofspeeche => partofspeeche.name).join(', ')}</small>`
          }
          let etymon = ''
          if (mainLemma.treeParent){
            etymon = `<small> [${mainLemma.treeParent.display}]</small>`
          }
          if (mainLemma.etymon){
            etymon = `<small> [${mainLemma.etymon.display}]</small>`
          }
          retval = `${prefix}${mainLemma.lemmaForm}${sufix}${etymon}`
        }
        return retval
      },
    },
    watch: {
      $route(to, from) {
        if (to === from || !this.article) return;

        this.$store.dispatch('loadArticle', to.params.id)
      }
    },
    mounted () {
      this.$store.dispatch('loadArticle', this.$route.params.id)
    }
  }
</script>
