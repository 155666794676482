<template>
  <div class="dictionaries" v-if="dictionaries.length">
    <Select2
      :options="dictionaryOptions"
      v-model="selectedIds"
      @select="select($event)"
      :settings="{multiple: multipleOption, width: '100%'}"
    />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import Select2 from 'v-select2-component'

  export default {
    name: 'DictionarySelect',
    props: ['selected', 'multiple'],
    data(){
      return {
        selectedIds: this.selected,
        multipleOption: this.multiple == true ? true : false
      }
    },
    components: {
      Select2
    },
    mounted() {
      this.$store.state.common.dictionaries = []
      this.$store.dispatch('loadDictionaries')
    },
    computed: {
      ...mapState({
        dictionaries: state => state.common.dictionaries
      }),
      dictionaryOptions(){
        return this.dictionaries.map(dictionary => {
          let langCode = dictionary.languageCode ? ` (${dictionary.languageCode})` : ''
          return {
            id: dictionary.id,
            text: `${dictionary.acronym}${langCode}`,
            dictionary: dictionary
          }
        }).sort((a, b) => (a.text > b.text) ? 1 : -1)
      }
    },
    methods: {
      select(){
        // this.$emit('select', this.selectedIds)
        let selectedDictionaries = this.dictionaries.filter(dictionary => this.selectedIds.includes(dictionary.id.toString()))
        this.$emit('select', selectedDictionaries)
      }
    }
  }
</script>
