<template>
  <div class="articles">
    <b-container fluid="xxl" class="">
      <b-row class="text-center">
        <b-col>
          <input
            class="px-2 py-1 w-100 form-control"
            id="search"
            type="text"
            size="sm"
            placeholder="Search article"
            @keyup="getSearchResults()"
            autocomplete="off"
            v-model="query"
          />
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <div v-if="$store.state.user.settings.isDictionaryFiltered" class="mb-3 text-orange">
            <b-icon icon="exclamation-circle-fill" aria-hidden="true"></b-icon>
            Articles are filtered by personal preference for dictionary set in user settings
            ({{ $store.getters.getDictionariesByIds($store.state.user.settings.dictionaries).map(dictionary => dictionary.acronym).join(', ') }})
          </div>
          <ul v-if="articles.length || allowNew" class="results-ul">
            <li v-for="article in articles" :key="article.id">
              <a href="#" @click="selectArticle($event, article)">
                {{ article.mainLemma.lemmaForm }} <span v-if="article.dictionary">({{ article.dictionary.acronym }})</span>
              </a>
            </li>
            <li v-if="allowNew && clearQuery">
              <a href="#" @click="newArticle($event)">Create new <b>{{ clearQuery }}</b></a>
            </li>
          </ul>
          <span v-else>
            <span v-if="query">No results</span>
            <span v-else>Start searching</span>
          </span>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'ArticleAutocomplete',
    props: ["allowNew"],
    data() {
      this.$store.state.article.articles = []
      return {
        query: '',
        timer: null
      }
    },
    mounted() {
      this.$store.state.article.articles = []
    },
    computed: {
      ...mapState({
        articles: state => state.article.articles,
        userSettings: state => state.user.settings
      }),
      clearQuery(){
        return this.query.replace(/\*/gi, '')
      }
    },
    watch: {
      "userSettings"(){
        this.getSearchResults()
      }
    },
    methods: {
      selectArticle(event, article){
        event.preventDefault()
        this.$emit('selectArticle', article)
        this.$store.state.article.articles = []
        this.query = ''
      },
      getSearchResults(){
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.$store.dispatch('searchArticles', {
            query: this.query.toLowerCase(),
            dictionaries: this.userSettings.dictionaries
          })
        }, 400)
      },
      newArticle(event){
        event.preventDefault()
        this.$emit('selectArticle', {
          id: null,
          lemmaForm: this.clearQuery
        })
      }
    }
  }
</script>
