<template>
  <div v-if="articleauthors.length">
    <Select2 :options="selectOptions" v-model="selectedIds" @select="select($event)" :settings="{multiple: true}" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import Select2 from 'v-select2-component'

  export default {
    name: 'ArticleauthorSelect',
    props: ['selected'],
    data(){
      return {
        selectedIds: this.selected
      }
    },
    components: {
      Select2
    },
    mounted() {
      this.$store.dispatch('loadArticleauthors')
    },
    computed: {
      ...mapState({
        articleauthors: state => state.common.articleauthors
      }),
      selectOptions(){
        return this.articleauthors.map(articleauthor => {
          return {
            id: articleauthor.id,
            text: articleauthor.name,
            articleauthor: articleauthor
          }
        }).sort((a, b) => (a.text > b.text) ? 1 : -1)
      }
    },
    methods: {
      select(){
        let selectedObjects = this.articleauthors.filter(articleauthor => this.selectedIds.includes(articleauthor.id.toString()))
        this.$emit('select', selectedObjects)
      }
    }
  }
</script>
