<template>
  <div class="article">
    <b-container fluid="xxl" v-if="article && article.mainLemma">
      <b-row>
        <b-col v-if="config.editable">
          <b-form-checkbox
            v-model="article.isPublished"
            @change="saveArticle"
            size="sm"
            class="mb-2"
            name="check-button"
            switch
            :disabled="!$store.getters.checkRole('legam.dict.article.modify') || !config.editable"
          >
            Published
          </b-form-checkbox>

          <b-form-checkbox
            v-model="article.isDoclinged"
            @change="saveArticle"
            size="sm"
            class="mb-4"
            name="check-button"
            switch
            :disabled="!$store.getters.checkRole('legam.dict.article.modify') || !config.editable"
          >
            DocLing finished
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-group
            id="authors-group"
            label="this.$t('ArticleAuthors')"
            label-for="authors"
          >
            <ArticleauthorSelect
              v-if="$store.getters.checkRole('legam.dict.article.modify')"
              :selected="article.articleauthors.map(articleauthor => articleauthor.id)"
              @select="selectArticleauthor"
            />
            <span v-else-if="article.articleauthors.length" class="text-bold">
              {{ article.articleauthors.map(author => author.name).join(", ") }}
            </span>
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col cols="3">
          <h6 class="mt-5 legam-h6 with-line">
            {{ $t('Lemmas') }}
            <!-- <span class="btn-legam-xs btn-legam-xxs ml-2" @click="showAddNewLemma" v-if="currentTab == 'tree'">
              <b-icon icon="plus"></b-icon>
              Add
            </span> -->
          </h6>
          <!-- <b-tabs content-class="mt-3 ml-2" class="legam-tree-list-tabs" align="right"> -->
            <div class="add-new-box" v-if="showAddNewBox">
              <LemmaAutocomplete
                :allowNew="true"
                :withoutArticle="true"
                @select="addNewLemma"
                @close="closeAddNewLemma"
              />
            </div>
            <!-- <b-tab title="List" active @click="tabChange('list')">
              <span v-if="article">
                <p v-for="lemmaId in sortedLemmas()" :key="lemmaId" class="tooltip-box mb-2" :class="{'lemma-highlighted': lemmaId == $route.query.lemmaId}">
                  <span v-if="lemmaId != article.mainLemmaId" class="ml-3"></span>
                  ● {{ article.lemmas[lemmaId].lemmaForm }}
                  <span class="red" v-if="!article.lemmas[lemmaId].tree || article.lemmas[lemmaId].tree.checked == false" v-b-tooltip.hover title="Unchecked">✕</span>
                  <span class="tooltiptext">
                    <button @click="loadLemma('left', lemmaId)" class="btn-legam-tooltip">
                      <b-icon icon="layout-sidebar-inset"></b-icon>
                      Open left
                    </button>
                    <button @click="loadLemma('right', lemmaId)" class="btn-legam-tooltip">
                      <b-icon icon="layout-sidebar-inset-reverse"></b-icon>
                      Open right
                    </button>
                  </span>
                </p>
              </span>
            </b-tab> -->
            <!-- <b-tab title="Tree" @click="tabChange('tree')"> -->
              <TreeView
                v-if="article"
                @save="saveTree"
                :bullet="''"
                v-bind:data="treeForLemma"
                @add="showAddNewBox=true"
                @cancel="showAddNewBox=false"
                :treeMaxRoots="1"
                :disableEdit="!$store.getters.checkRole('legam.dict.lemmatree.rearrange')"
                :disableAdd="!$store.getters.checkRole('legam.dict.article.modify')"
              >
                <template v-slot:leafNameDisplay="slotProps">
                  <span @click="loadLemma('left', slotProps.model.id)">{{ slotProps.model.name }}</span>
                  <span class="red" v-if="slotProps.model.checked == false" v-b-tooltip.hover title="Unchecked">✕</span>
                  <span class="contextmenu" v-if="$store.getters.checkRole('legam.dict.lemmatree.rearrange')">
                    <button @click="loadLemma('left', slotProps.model.id)" class="btn-legam-tooltip">
                      <b-icon icon="layout-sidebar-inset"></b-icon>
                      Open
                    </button>
                    <!-- <button @click="loadLemma('right', slotProps.model.id)" class="btn-legam-tooltip">
                      <b-icon icon="layout-sidebar-inset-reverse"></b-icon>
                      Open right
                    </button> -->
                    <div class="separator"></div>
                    <button v-if="config.editable" @click="deleteLemma(slotProps.model.id)" class="btn-legam-tooltip">
                      <b-icon icon="trash-fill"></b-icon>
                      Remove
                    </button>
                    <button v-if="config.editable" @click="moveSubtree(slotProps.model.id)" class="btn-legam-tooltip">
                      <b-icon icon="box-arrow-right"></b-icon>
                      Move to...
                    </button>
                  </span>
                </template>
              </TreeView>
              <b-modal id="move-to-article" title="Move lemma to another article" ok-title="Close" size="lg">
                <ArticleAutocomplete :allowNew="false" @selectArticle="addLemmaSubtreeToArticle" />
                <!-- <div class="ml-3 mt-2">
                  <a @click="moveToNewArticle" href="#" v-if="selectedLemmaToMove">
                    <small>Create new article for</small> <b>{{ selectedLemmaToMove.lemmaForm }}</b>
                  </a>
                </div> -->
              </b-modal>
            <!-- </b-tab>
          </b-tabs> -->

          <div v-if="config.editable">

            <hr class="mt-4 mb-4">

            <b-form-group
              id="article-comment-group"
              label="Comment:"
              label-for="comment-contextLeft"
            >
              <b-form-textarea
                id="comment-contextLeft"
                v-model="article.comment"
                placeholder="Comment"
                rows="3"
                max-rows="6"
                @change="saveArticle"
                :disabled="!$store.getters.checkRole('legam.dict.article.modify')"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group
              id="dictionary-group"
              label="Dictionary:"
              label-for="dictionary"
            >
              <!-- <span v-html="article.dictionary.languageCode"></span> -->
              <DictionarySelect
                :selected="article.dictionaryId"
                @select="setDictionary"
                :multiple="false"
              />
            </b-form-group>

            <b-form-group
              id="scripta-group"
              label="Scriptae:"
              label-for="scripta"
            >
              <span v-html="article.mainLemma.scriptas.map(scripta => scripta.code).join(', ')"></span>
            </b-form-group>

            <b-form-group
              id="parts-of-speech-group"
              label="Parts of speech:"
              label-for="parts-of-speech"
            >
              <span v-html="article.mainLemma.partofspeeches.map(partofspeeche => partofspeeche.name).join(', ')"></span>
            </b-form-group>
          </div>
        </b-col>
        <b-col>
          <b-row :class="(leftLemmaId && rightLemmaId) ? 'vertical-scroll' : ''">
            <b-col class="lemmaBox" v-if="leftLemmaId">
              <LemmaEditDetails
                :lemmaId="leftLemmaId"
                @close="leftLemmaId=null"
                :isEdit="true"
                :dictionaryId="article.dictionaryId"
                @update="updateArticle"
              />
            </b-col>

            <b-col class="lemmaBox" v-if="rightLemmaId">
              <LemmaEditDetails
                :lemmaId="rightLemmaId"
                @close="rightLemmaId=null"
                :isEdit="true"
                :dictionaryId="article.dictionaryId"
                @update="updateArticle"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <hr>
      <b-row v-if="config.editable">
        <b-col>
          <div class="mt-4">
            <h6>Logs</h6>
            <ChangeLogsTable :objectType="'article'" :objectId="article.id" :key="'logs' + index" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
  /* .lemmaBox {
    overflow-y: scroll;
    height: 100%;
  } */

  .vertical-scroll {
    height: 100vh;
    position: relative;
  }

  .lemma-highlighted {
    background-color: rgb(211, 243, 255);
    border-radius: 4px;
    padding: 5px;
  }

  .add-new-box {
    margin-top: -16px;
    background-color: #f1f1f1;
    margin-left: -8px;
    padding: 20px 7px 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    margin-bottom: 10px;
  }

  .move-to-article-box {
    margin-top: 10px;
    padding: 20px 0px 20px;
    border-radius: 5px;
    background-color: #f1f1f1;
    margin-bottom: 10px;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import LemmaEditDetails from '@/components/Lemma/EditDetails.vue'
  import LemmaAutocomplete from '@/components/Lemma/Autocomplete.vue'
  import DictionarySelect from '@/components/DictionarySelect.vue'
  import ArticleAutocomplete from '@/components/ArticleAutocomplete.vue'
  import ArticleauthorSelect from '@/components/Dictionary/ArticleauthorAutocomplete.vue'
  import TreeView from '@/components/TreeView.vue'
  import ChangeLogsTable from '@/components/ChangeLogsTable.vue'
  import router from '@/router'
  import config from '@/config.js'

  export default {
    name: 'ArticleDetails',
    components: {
      LemmaEditDetails,
      LemmaAutocomplete,
      TreeView,
      ArticleAutocomplete,
      ChangeLogsTable,
      ArticleauthorSelect,
      DictionarySelect,
    },
    data(){
      return {
        leftLemmaId: null,
        rightLemmaId: null,
        occurrenceDetailsModel: null,
        logs: [],
        showAddNewBox: false,
        currentTab: 'list',
        moveToArticleLemmaId: null,
        index: 0,
        config: config,
      }
    },
    computed: {
      selectedLemmaToMove(){
        let retVal = null
        if (this.moveToArticleLemmaId){
          retVal = this.article.lemmas[this.moveToArticleLemmaId]
        }
        return retVal
      },
      treeForLemma(){
        return [this.makeLemmaTree(this.article.lemmaTree, this.leftLemmaId)]
      },
      ...mapState({
        article: state => state.article.article
      })
    },
    methods: {
      saveArticle(){
        this.$store.dispatch('saveArticle', this.article).then(() => {
          this.index++
        })
      },
      setDictionary(dictionaries){
        let dictionary = dictionaries[0]
        this.article.dictionary = dictionary
        this.article.dictionaryId = dictionary.id
        this.saveArticle()
      },
      loadLemma(side, lemmaId){
        if (side == 'left'){
          this.leftLemmaId = lemmaId
          this.makeLemmaTree(this.article.lemmaTree, lemmaId)
        }
        else {
          this.rightLemmaId = lemmaId
        }
      },
      makeLemmaTree(jsonData, selLemmaId){
        return {
          id: jsonData.id,
          name: (jsonData.id) ? this.article.lemmas[jsonData.id].lemmaForm : null,
          highlight: jsonData.id == selLemmaId,
          checked: (this.article.lemmas[jsonData.id].tree ? this.article.lemmas[jsonData.id].tree.checked : false),
          children: jsonData.children.map(node => {
            return this.makeLemmaTree(node, selLemmaId)
          })
        }
      },
      sortedLemmas(node){
        let lemmas = []
        if (this.article){
          if (!node){
            node = this.article.lemmaTree
          }
          if (node){
            lemmas.push(node.id)
            for(let x of node.children){
              lemmas = lemmas.concat(this.sortedLemmas(x))
            }
          }
        }
        if (this.$store.state.user.settings.listViewOrder == 'lexical'){
          lemmas.sort((lemmaAId, lemmaBId) => {
            let lemmaA = this.article.lemmas[lemmaAId]
            let lemmaB = this.article.lemmas[lemmaBId]
            let retval = 0
            if (lemmaA.id == this.article.mainLemmaId){
              return -2
            }
            else if (lemmaB.id == this.article.mainLemmaId){
              return 2
            }
            else if (lemmaA.lemmaForm > lemmaB.lemmaForm){
              retval = 1
            }
            else if (lemmaA.lemmaForm < lemmaB.lemmaForm){
              retval = -1
            }
            return retval
          })
        }
        return lemmas
      },
      moveSubtree(nodeId){
        this.moveToArticleLemmaId = nodeId
        this.$bvModal.show('move-to-article')
        // this.$bvModal.msgBoxConfirm('Are you sure?')
        //   .then(value => {
        //     if (value){

        //     }
        //   })
      },
      saveTree(tree){
        this.showAddNewBox = false
        this.$store.dispatch('saveArticleTree', {
          tree: tree[0],
          articleId: this.article.id
        }).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Article tree saved`
          })
          this.index++
        })
      },
      showAddNewLemma(){
        this.showAddNewBox = true
      },
      closeAddNewLemma(){
        this.showAddNewBox = false
      },
      tabChange(tab){
        this.currentTab = tab
        if (tab == 'list'){
          this.showAddNewBox = false
        }
      },
      addNewLemma(lemma){
        this.article.lemmas[lemma.id] = lemma
        if (this.article.lemmaTree === null){
          this.article.lemmaTree = {
            id: lemma.id,
            children: []
          }
        }
        else {
          this.article.lemmaTree.children.push({id: lemma.id, children: []})
        }
        this.$store.dispatch('saveArticleTree', {
          tree: this.article.lemmaTree,
          articleId: this.article.id
        }).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Lemma added to article`
          })
          this.closeAddNewLemma()
        })
      },
      getSubLemmas(lemmaId) {
        let retVal = []
        let that = this

        function findSubtree(findLemmaId, root){
          let retVal = null
          if (root.id == findLemmaId) {
            return root
          }

          for(let lemma of root.children) {
            retVal = findSubtree(findLemmaId, lemma)
            if (retVal){
              break
            }
          }
          return retVal
        }

        // Find node
        let node = findSubtree(lemmaId, this.article.lemmaTree)

        function recNode(node){
          let retVal = []
          for(let lemmaTree of node.children) {
            retVal.push(that.article.lemmas[lemmaTree.id])
            retVal = retVal.concat(recNode(lemmaTree))
          }
          return retVal
        }

        if (node && node.children){
          retVal = recNode(node)
        }
        return retVal
      },
      deleteLemma(lemmaId) {
        let text = ['Are you sure?']
        let lemmas = this.getSubLemmas(lemmaId).map(lemma => lemma.lemmaForm).join(', ')

        if (lemmas) {
          text.push(this.$createElement('div', {
            class: {
              'mt-2': true
            }
          }), `The following sublemma(s) ${lemmas} will be also removed removed from the article!`)
        }
        this.$bvModal.msgBoxConfirm(this.$createElement('div', text))
          .then(value => {
            if (value){
              this.deleteNode(lemmaId)
              this.$store.dispatch('saveArticleTree', {
                tree: this.article.lemmaTree,
                articleId: this.article.id
              }).then(() => {
                this.$store.dispatch('addNotification', {
                  type: 'success',
                  text: `Lemma removed from article`
                })
              })
            }
          })
      },
      deleteNode(lemmaId){
        // TODO: move to utils
        function recDeleteNode(children, lemmaIdToDelete){
          let retVal = []
          for(let lemma of children){
            if (lemma.id != lemmaIdToDelete){
              lemma.children = recDeleteNode(lemma.children, lemmaIdToDelete)
              retVal.push(lemma)
            }
          }
          return retVal
        }

        delete this.article.lemmas[lemmaId]

        // If we're removing root redirect to article search
        if (this.article.lemmaTree.id == lemmaId){
          this.article.lemmaTree = {}
          router.push('/lemmas/')
        }
        else {
          this.article.lemmaTree = {
            id: this.article.lemmaTree.id,
            children: recDeleteNode(this.article.lemmaTree.children, lemmaId)
          }

          if (this.leftLemmaId == lemmaId){
            this.leftLemmaId = null
          }
          if (this.rightLemmaId == lemmaId){
            this.rightLemmaId = null
          }
        }
      },
      addLemmaSubtreeToArticle(article){
        this.$bvModal.msgBoxConfirm('Are you sure?')
          .then(value => {
            if (value){
              this.$bvModal.hide('move-to-article')
              this.$store.dispatch('moveLemmaToArticle', {
                newArticleId: article.id,
                lemmaId: this.moveToArticleLemmaId,
                articleId: this.article.id
              }).then(() => {
                this.deleteNode(this.moveToArticleLemmaId)
                this.moveToArticleLemmaId = null
                this.$store.dispatch('addNotification', {
                  type: 'success',
                  text: `Lemma subtree moved to another article`
                })
              })
            }
          })
      },
      selectArticleauthor(articleauthors){
        this.article.articleauthors = articleauthors
        this.saveArticle()
      },
      moveToNewArticle(event){
        event.preventDefault()
        this.addLemmaSubtreeToArticle(null)
      },
      updateArticle(){
        this.$store.dispatch('loadArticle', this.article.id)
        this.index++
      }
    }
  }
</script>
